import React, { useEffect, useState } from "react";
import "./CustomBootstrap.scss";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { Card, Button, Row, Col } from "react-bootstrap";
import BannerImage from "./BannerImage";

export default function EditServiceClass() {
  const navigate = useNavigate();
  const [productCount, setProductCount] = useState(0);
  const [productsInfo, setProductsInfo] = useState([]);
  const firebaseAddress = process.env.REACT_APP_SERVER_URL;



  useEffect(() => {
      const getProductList = () => {
          // Post to server retrieve questions from database
          axios
              .get(`${firebaseAddress}getproductList`, {})
              .then((res) => {
                  const products = res.data.data;
                  const listOfProducts = [];
                  for (const product in products) {
                      listOfProducts.push({ [product]: products[product] });
                  }
                  setProductsInfo(listOfProducts);
                  setProductCount(Object.keys(products).length);
              })
              .catch((err) => {});
      };

    getProductList();
  }, [firebaseAddress]);

  return (
    <Card
      style={{
        border: "none",
        boxShadow: "0px 0px 5px 0px rgba(0, 0, 0, 0.25)",
      }}
    >
      <Card.Body>
      <BannerImage />
        <h4 className="mt-3">
          <b>Edit Subscription Tiers</b>
        </h4>
        <hr className="mt-0" />
        <h4 className="mb-1 d-flex justify-content-center">
          Existing Subscription Tiers: {productCount}
        </h4>
        <Row>
          {productsInfo.map((product) => {
            const className = Object.values(product)[0].product_name;
            const id = Object.keys(product)[0];
            return (
              <Col md={6} sm={12}>
                <Link
                  to={{
                    pathname: `/edit-class/${id}`,
                  }}
                  state={{ id: id }}
                  key={id}
                  className="btn btn-outline-secondary w-100 mt-3"
                >
                  {className}
                </Link>
              </Col>
            );
          })}
          <hr
            style={{
              height: "3px",
              backgroundColor: "white",
              color: "white",
              width: "96%",
            }}
          />
          <Button
            onClick={() => {
              navigate("/create-class");
            }}
            variant="danger"
            style={{ backgroundColor: "#800000", border: "none" }}
          >
            Add Subscription Tier
          </Button>
        </Row>
      </Card.Body>
    </Card>
  );
}
