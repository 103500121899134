import "./CustomBootstrap.scss";
import { Button, Table, Col, Stack } from "react-bootstrap";
import "./Files.css";
import "./styles/Files.css";
import {useCallback, useEffect, useState} from "react";
import axios from "axios";
import ComponentCard from "./ComponentCard.js";
import { Modal, Form } from "react-bootstrap";
import { formCheck, uint8ArrayToString } from "../functions/encoding";
import {
  downloadFileMicroSoft,
  sendEmailMicrosoft,
} from "../util/SecureCommunication";
import { createUserKey, XOR } from "../functions/encoding";
import { shareFileOnedrive } from "./Open";
import { useNavigate } from "react-router-dom";
import LoadingButton from "./LoadingButton";
import { useCookies } from "react-cookie";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faEye, faEyeSlash, faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import FeedbackAlert from "./FeedbackAlert";
import PasswordWrapper from "./PasswordWrapper";
import { getUserFromEmail } from "../functions/getUser";
library.add(faEye, faEyeSlash, faCircleInfo);


const SERVER_URL = process.env.REACT_APP_SERVER_URL;
const WEB_URL = process.env.REACT_APP_FRONTEND_URL;
// const CryptoJS = require("crypto-js");

function bytesToString(bytes) {
  const denominations = ["KB", "MB", "GB", "TB"];
  // 1 KB = 2^10 bytes.
  // 1 MB = 2^20 bytes.
  // 1 GB = 2^30 bytes, and so on.
  const bitPlaces = Math.floor(Math.log2(bytes));
  let index = Math.floor(bitPlaces / 10) - 1;
  if (index < 0) {
    index = 0;
  } else if (index > 3) {
    index = 3;
  }
  const largestUnitInBytes = Math.pow(2, (index + 1) * 10);

  const formatter = new Intl.NumberFormat("en-US", {
    minimumFractionDigits: 1,
    maximumFractionDigits: 2,
  });
  const formattedNumber = formatter.format(bytes / largestUnitInBytes);
  return `${formattedNumber} ${denominations[index]}`;
}

export default function Sheets(props) {
  const [fileData, setFileData] = useState([]);
  const [fileLimit, setFileLimit] = useState(0);
  const [password, setPassword] = useState("");
  const [showModalEmail, setShowModalEmail] = useState(false);
  const [showModalDelete, setShowModalDelete] = useState(false);
  const [showModalMDelete, setShowModalMDelete] = useState(false);
  const [showModalUCDelete, setShowModalUCDelete] = useState(false);
  const [selected, setSelected] = useState(false);
  const [selectedFile, setSelectedFile] = useState("");
  const [sharedWithData, setSharedWithData] = useState([]);
  const [showSharedWithTable, setShowSharedWithTable] = useState(false);
  const [decryptFormFeedback, setDecryptFormFeedback] = useState(null);
  const [remindersSent, setRemindersSent] = useState([]);
  const [cookies] = useCookies(["accessToken"]);
  const navigate = useNavigate();
  const [modalFeedback, setModalFeedback] = useState(null);
  const [isSponsored, setIsSponsored] = useState(false);
  const [items, setItems] = useState([]);

  const getFiles = useCallback( () =>
  {
    var combined = password + props.user.id;
    var hashed = formCheck(combined);
    axios
        .post(SERVER_URL + "getFileList", {
          userId: props.user.id,
          toCheck: hashed,
        })
        .then((res) => {
          let arr = [];
          for (const item in res.data.data.fileInfo) {
            arr.push({...res.data.data.fileInfo[item], fileID: item});
          }
          arr.sort(compareDates);
          setFileData(arr);
        })
        .catch((err) => {
          console.log(err)
        })
  }, [password, props.user.id]);

  function compareDates(a, b) {
    if (a.date < b.date) {
      return 1;
    }
    if (a.date > b.date) {
      return -1;
    }
    return 0;
  }


  const deleteFile = (fileID) => {
    var combined = "test123456" + props.user.id;
    var hashed = formCheck(combined);
    axios
      .post(SERVER_URL + "removeTransformedKey", {
        userID: props.user.id,
        fileId: fileID,
        toCheck: hashed,
      })
      .then((res) => {
        getFiles();
        if (res.data.success) {
          setSelected(false);
          // alert("File deleted!");
        } else {
          alert("Oops, something went wrong...");
        }
      });
  };

  // checks if user is sponsored, then checks password, finds files, and checks file limit
  useEffect(() => {
    function checkFileLimit(sponsorId) {
      if (sponsorId) {
        // if sponsored, check sponsor file limit and file count, and subtract to get file encryptions remaining
        axios
            .post(SERVER_URL + "checkFileLimit", {
              userId: sponsorId,
            })
            .then((res) => {
              const data = res.data.data;
              setFileLimit(data.fileLimit - data.fileCount);
            })
            .catch((err) => { });
      } else {
        axios
            .post(SERVER_URL + "checkFileLimit", {
              userId: props.user.id,
            })
            .then((res) => {
              const data = res.data.data;
              setFileLimit(data.fileLimit);
            })
            .catch((err) => { });
      }
    }

    async function checkIfSponsored() {
      return await axios
          .post(`${SERVER_URL}getUserSponsorship`, {
            user_id: props.user.id,
          })
          .then((res) => {
            const data = res.data.data;
            if (Object.hasOwn(data, 'sponsor_id')) {
              return data.sponsor_id;
            } else {
              return null;
            }
          })
          .catch((err) => {
            throw err;
          })
    }
    ;

    async function checkSponsor() {
      getFiles();
      let sponsorId = await checkIfSponsored();
      if (sponsorId) {
        setIsSponsored(true);
        checkFileLimit(sponsorId);
      } else {
        checkFileLimit(null);
      }
    }
    if (password) {
      checkSponsor();
    }
  }, [password, getFiles, props.user.id]);



  const clicked = (file) => {
    setSelected(true);
    setSelectedFile(file);
    // console.log("check items file id", (items.some((item) => item.fileID === file.fileID)))

    // Adding into the list
    if ((!items.some((item) => item.fileID === file.fileID))) {
      const newItem = {
        fileID: file.fileID,
        isClicked: true,
        file: file

      };
      setItems([...items, newItem]);

    }

    // Deleting from the list
    if ((items.some((item) => item.fileID === file.fileID)) && (items.length > 2 || (items.length === 1))) {
      const updatedItems = items.filter((item) => item.fileID !== file.fileID);
      setItems(updatedItems);
      // console.log("inside delete", items)
    }

    else if ((items.some((item) => item.fileID === file.fileID)) && items.length === 2) {

      const updatedItems = items.filter((item) => item.fileID !== file.fileID);
      setItems(updatedItems);
      // console.log("inside delete", items)
      if (!(items[0].file === file)) {
        setSelected(true);
        setSelectedFile(items[0].file)
      }
      else {
        setSelected(true);
        setSelectedFile(items[1].file)
      }

    }

    // console.log(items)

  };

  const deleteMutipleFiles = (myList) => {
    // console.log(myList.length);
    myList.map(item => deleteFile(item.fileID));
    axios
      .post(SERVER_URL + "setFileCount", {
        user_id: props.user.id,
        remaining_files: (fileData.length - myList.length)
      })
      .then((res) => {
        // console.log("response", res)
      })
    alert("Files Deleted");
    setItems([]);
  }


  const handlecheckbox = (e) => {
    const { value, checked } = e.target;
    // console.log(value);
    // console.log(checked)
    if (checked === true && (!items.some((item) => item.fileID === value))) {
      const newItem = {
        fileID: value,
        isClicked: true

      };
      setItems([...items, newItem]);

    }

    if (checked === false && (items.some((item) => item.fileID === value))) {
      const updatedItems = items.filter((item) => item.fileID !== value);
      setItems(updatedItems);
    }

    // console.log(items)

  }

  const handleSharedWithClick = (index) => {
    setSharedWithData(
      Object.values(fileData[index]["sharedUsers"]).map((obj) => {
        return { ...obj, index: index };
      })
    );
    setShowSharedWithTable(true);
  };

  let sharedWithTable = !showSharedWithTable ? null : (
    <>
      <Table hover>
        <thead className="small">
          <tr>
            <th className="table-header">Email</th>
            <th className="table-header">Claimed</th>
            <th className="table-header" style={{ textAlign: "center" }}>
              Reminder
            </th>
          </tr>
        </thead>
        <tbody>
          {sharedWithData.map((item) => {
            const sentReminder = remindersSent.includes(item.email);
            return (
              <tr key={item.email}>
                <td className="table-data">{item.email}</td>
                <td className="table-data">{item.claimed.toString()}</td>
                <td className="table-data" style={{ textAlign: "center" }}>
                  <Button
                    size="sm"
                    variant="primary"
                    disabled={item.claimed || sentReminder}
                    onClick={() => sendReminder(item.index, item.email)}
                  >
                    {sentReminder ? "Reminded" : "Remind"}
                  </Button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </>
  );

  const sendReminder = (index, email) => {
    let encRandKey, encHashedKey;
    let hashed = createUserKey(fileData[index].fileID, password, props.user.id);
    for (let shareKeyRef in fileData[index]) {
      if (fileData[index][shareKeyRef].email === email) {
        encRandKey = new Uint8Array(fileData[index][shareKeyRef].keyR);
        encHashedKey = new Uint8Array(fileData[index][shareKeyRef].keyH);
      }
    }
    let subject =
      'Cynorix Secure File Sharing: REMINDER "' + fileData[index].name + '"',
      emailBody = "";
    var key1 = XOR(encRandKey, encHashedKey);
    var keyStr = uint8ArrayToString(XOR(key1, hashed));
    var receiveUrl =
      WEB_URL +
      "receive?fileId=" +
      fileData[index].fileID +
      "&key=" +
      keyStr +
      "&owner=" +
      props.user.email +
      "&recip=" +
      email +
      "&keyRef=" +
      "null";

    emailBody =
      props.user.name +
      " reminds you to claim the following file: <b>" +
      fileData[index].name +
      "</b><br>" +
      'Please click <a id="sign-in" href="' +
      receiveUrl +
      '">here</a> to claim the file with Cynorix Secure File Sharing<br>';
    sendEmailMicrosoft(
      subject,
      emailBody,
      email,
      props.user,
      cookies.accessToken
    )
      .then((res) => {
        if (String(res.status).charAt(2) === '2') {
          alert("Reminder is sent!");
          setRemindersSent([...remindersSent, email]);
        } else {
          alert("Oops, something went wrong...");
        }
      })
      .catch((err) => { });
  };
  const helpContent = (
    <p className="mb-1">
      The File Table presents relevant information about each set of encrypted
      files uploaded and shared with a user. Users can see each user that they
      have shared a file with, including their claim status. In addition to
      claiming through the automatic email, users can claim files by selecting
      the file and clicking the Claim button. In order to use the Download and
      Share options, the selected file must be claimed. Whether or not a file is
      claimed, it can be deleted.
    </p>
  );
  return (
    <ComponentCard title="Files" helpContent={helpContent}>
      <Modal show={showModalUCDelete}>
        <Modal.Header>
          <Modal.Body>
            You have not claimed this file. Are you sure you want to delete it?
          </Modal.Body>
        </Modal.Header>
        <Modal.Footer>
          <Button
            onClick={() => {
              setShowModalUCDelete(false);
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={async () => {
              deleteMutipleFiles(items)
              setItems([])
              setShowModalUCDelete(false);
            }}
          >
            Delete
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showModalMDelete}>
        <Modal.Header>
          <Modal.Body>
            Are you sure you want to delete these files? Some of them may not be claimed by all the shared users.
          </Modal.Body>
        </Modal.Header>
        <Modal.Footer>
          <Button
            onClick={() => {
              setShowModalMDelete(false);
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={async () => {
              deleteMutipleFiles(items)
              setItems([])
              setShowModalMDelete(false);
            }}
          >
            Delete
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={showModalEmail}
        onHide={() => {
          setShowModalEmail(false);
          setModalFeedback(null);
        }}
        keyboard={false}
        backdrop="static"
      >
        <Form
          noValidate
          onSubmit={async (e) => {
            e.preventDefault();
            if (e.target.checkValidity() === false) {
              setModalFeedback({
                message: "Please enter a valid email address.",
                variant: "danger",
              });
              e.stopPropagation();
              return;
            }

            // sharefile does not need to pass on the last param,
            // as the pwd is already validated
            try {
              await shareFileOnedrive(
                e.target.recipientEmail.value.toLowerCase(),
                password,
                selectedFile.fileID,
                cookies.accessToken,
                props.user,
                setModalFeedback,
                () => {
                  navigate("/unlock");
                },
                selectedFile.copyFileId ? selectedFile.copyFileId : "",
                props.user
              );
              getFiles();
              // on success, clear form fields
              setTimeout(() => {
                e.target.reset();
              }, 300);
            } catch (e) {
              setModalFeedback(
                "An unexpected error occurred while sharing file."
              );
            }
          }}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <b>Share File</b>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Provide the email of the person you want to share{" "}
            <b>{selectedFile.name}</b> with.
            <Form.Group className="my-3 row" controlId="share-recip-email">
              <Col xs={12} md={"auto"}>
                <Form.Label column>Recipient</Form.Label>
              </Col>
              <Col>
                <Form.Control
                  name="recipientEmail"
                  type="email"
                  placeholder="Email"
                  autoComplete="off"
                  required
                />
              </Col>
            </Form.Group>
            <LoadingButton
              disabled={modalFeedback && modalFeedback.loading}
              className="w-100"
              type="submit"
            >
              Submit
            </LoadingButton>
            <FeedbackAlert feedback={modalFeedback} className="mt-3 mb-0" />
          </Modal.Body>
        </Form>
      </Modal>

      <Modal show={showModalDelete}>
        <Modal.Header>
          <Modal.Body>
            At least one user has not claimed this file. By deleting this file,
            they will not be able to claim it.
          </Modal.Body>
        </Modal.Header>
        <Modal.Footer>
          <Button
            onClick={() => {
              setShowModalDelete(false);
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={async () => {
              deleteMutipleFiles(items)
              setItems([])
              setShowModalDelete(false);
            }}
          >
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
      <PasswordWrapper user={props.user} setPassword={setPassword}>
        <div className="File-background-box">
          {(selected && items.length === 1) && (
            <div className="selected-box">
              {'"' + selectedFile.name + '" selected'}
              <Stack direction="horizontal" gap={2}>
                {selectedFile.claimUrl !== undefined ? (
                  <LoadingButton
                    onClick={() => {
                      window.location.href = selectedFile.claimUrl;
                    }}
                  >
                    Claim
                  </LoadingButton>
                ) : null}
                <LoadingButton
                  loading={
                    decryptFormFeedback !== null &&
                    decryptFormFeedback.loading === true
                  }
                  onClick={() => {
                    if (selectedFile.trash === undefined) {
                      alert("You must claim the file to download it");
                    } else {
                      let owner;
                      axios
                        .post(`${SERVER_URL}checkOwner`, {
                          userId: props.user.id,
                          fileId: selectedFile.fileID,
                        })
                        .then((res) => {
                          owner = res.data.data;
                          downloadFileMicroSoft(
                            password,
                            selectedFile.fileID,
                            props.user,
                            cookies.accessToken,
                            owner,
                            setDecryptFormFeedback,
                            () => {
                              navigate("/unlock");
                            }
                          );
                        });
                    }
                  }}
                  variant="primary"
                >
                  Download
                </LoadingButton>
                <Button
                  disabled={false}
                  variant="primary"
                  onClick={async () => {
                    const userData = (await getUserFromEmail(props.user.email)).userData
                    let owner;
                    axios
                      .post(`${SERVER_URL}checkOwner`, {
                        userId: props.user.id,
                        fileId: selectedFile.fileID,
                      })
                      .then(async (res) => {
                        owner = res.data.data;
                        let remain = "";
                        axios
                          .post(`${SERVER_URL}checkRemain`, {
                            userId: userData.sponsor_id ? userData.sponsor_id : props.user.id,
                          })
                          .then((res) => {
                            remain = res.data.data;
                            if (selectedFile.trash === undefined) {
                              alert(
                                "You have not claimed this file so you cannot share it"
                              );
                            } else if (remain) {
                              alert("You have no file shares left.");
                            } else if (owner === true) {
                              setShowModalEmail(true);
                            } else {
                              setShowModalEmail(true);
                            }
                          });
                      });
                  }}
                >
                  Share
                </Button>
                <Button
                  onClick={() => {
                    axios
                      .post(`${SERVER_URL}checkUnclaimed`, {
                        userId: props.user.id,
                        fileId: selectedFile.fileID,
                      })
                      .then((res) => {
                        if (selectedFile.trash === undefined) {
                          // console.log("In if")
                          setShowModalUCDelete(true);
                        } else if (!res.data.data) {
                          alert("Are you sure that you want to delete the file?")
                          deleteMutipleFiles(items)
                          setItems([])
                        } else {
                          // console.log("In else")
                          setShowModalDelete(true);
                        }
                      });
                  }}
                  variant="primary"
                >
                  Delete
                </Button>
              </Stack>
            </div>
          )}
          {
            // Deleting multiple files
            (selected && items.length > 1) && (
              <div className="selected-box">
                {'Mutiple files selected'}
                <Stack direction="horizontal" gap={2}>
                  <Button className="align-right"
                    onClick={() => {
                      // alert("Are you sure you want to delete these files? Some of them may not be claimed by all the shared users.")
                      // deleteMutipleFiles(items)
                      setShowModalMDelete(true);
                    }}
                    variant="primary"
                  >
                    Delete Multiple Files
                  </Button>
                </Stack>
              </div>
            )
          }
          {!showSharedWithTable ? (
            <Table hover>
              <thead className="small" style={{ color: "#424242" }}>
                <tr>
                  <th>Select</th>
                  <th style={{ width: "40%" }}>Name</th>
                  <th>Shared With</th>
                  <th>Size</th>
                  <th className="text-center">Date Uploaded</th>
                  <th className="text-center">Time Uploaded</th>
                </tr>
              </thead>
              <tbody>
                {fileData.map((file, index) => {
                  var numUsers;
                  if (file.sharedUsers)
                     numUsers = Object.keys(file.sharedUsers).length;
                  else numUsers = 0;
                  let sharedWith = `${numUsers} ${numUsers > 1 || numUsers === 0 ? "people" : "person"
                    }`;
                  let file_size =
                    file.trash === undefined
                      ? "Unclaimed/" + bytesToString(file.fileSize)
                      : bytesToString(file.fileSize);
                  let file_time = "";
                  let file_date = "";
                  if (file.date !== undefined) {
                    file_date = file.date.split("T")[0];
                    if (parseInt(file.date.split("T")[1].slice(0, 2)) > 12) {
                      file_time =
                        (
                          parseInt(file.date.split("T")[1].slice(0, 2)) - 12
                        ).toString() +
                        file.date.split("T")[1].slice(2, 5) +
                        " PM";
                    } else if (
                      parseInt(file.date.split("T")[1].slice(0, 2)) === 12
                    ) {
                      file_time = file.date.split("T")[1].slice(0, 5) + " PM";
                    } else {
                      file_time = file.date.split("T")[1].slice(0, 5) + " AM";
                    }
                  }

                  return (
                    <tr
                      key={index}
                      style={{ cursor: "pointer" }}
                      onClick={() => clicked(file)}
                    >
                      <td className="table-data"><input type='checkbox' value={file.fileID} checked={(items.some((item) => item.fileID === file.fileID))} onChange={(e) => handlecheckbox(e)} /></td>
                      <td className="table-data">{file.name}</td>
                      <td>
                        <Button
                          className="table-data"
                          variant="light"
                          disabled={!numUsers}
                          onClick={(event) => {
                            handleSharedWithClick(index);
                          }}
                        >
                          {sharedWith}
                        </Button>
                      </td>
                      <td className="table-data">{file_size}</td>
                      <td className="table-data text-center">{file_date}</td>
                      <td className="table-data text-center">{file_time}</td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          ) : (
            sharedWithTable
          )}
        </div>
        {showSharedWithTable ? (
          <Button
            className="mb-3"
            variant={"danger"}
            onClick={() => {
              setShowSharedWithTable(false);
            }}
          >
            Return to File Table
          </Button>
        ) : null}
        <hr className="my-2 mt-0" />
        {!isSponsored &&
            <span className="filecount">
                {`${fileData.length} file${fileData.length === 1 ? "" : "s"}`} -{" "}
                {fileLimit} file limit
            </span>
          }
          {isSponsored &&
            <span className="filecount">
                {`${fileData.length} file${fileData.length === 1 ? "" : "s"}`} -{" "}
                {fileLimit} file encryptions remaining
            </span>
          }
      </PasswordWrapper>
    </ComponentCard>
  );
}
