import React, { useState, useEffect } from "react";
import Axios from "axios";
import { useNavigate } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.css";
import {
  Card,
  Alert,
  Row,
  Col
} from "react-bootstrap";
import { getUserFromEmail, getEmailFromId} from "../functions/getUser";
import BannerImage from "./BannerImage";
import LoadingButton from "./LoadingButton";

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

export default function CancelSponsorship(props) {
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [userData, setUserData] = useState("");
  const [sponsorData, setSponsorData] = useState("");
  const [sponsorEmail, setSponsorEmail] = useState("");
  const [loading, setLoading] = useState(true);


  const navigate = useNavigate();

  useEffect(() => {
    const getUsers = async () => {
        const tempUserData = (await getUserFromEmail(props.user.email)).userData;
        setUserData(tempUserData);
        // console.log(tempUserData)
        const tempSponsorEmail = await getEmailFromId(tempUserData.sponsor_id)
        const tempSponsorData = (await getUserFromEmail(tempSponsorEmail)).userData
        // console.log(tempSponsorData)
        setSponsorData(tempSponsorData)
        setSponsorEmail(tempSponsorEmail)
    }
    if (props.user.email) {
      getUsers()
    }
  }, [props.user.email]);

  useEffect(() => {
    if (sponsorEmail) {
        setLoading(false)
    }
  }, [sponsorEmail]);

  const removeSponsorship = async () => {
    // console.log(userData)
    await Axios.post(SERVER_URL + "removeSponsorship", {
      employeeData: userData,
      sponsorData: sponsorData,
      employeeId: props.user.id,
      sponsorId: userData.sponsor_id
    })
      .then(() => {
        setSuccess("Sponsorship terminated.");
        setTimeout(() => {
          navigate("/new")
        }, 1000);
      })
      .catch((err) => {
        console.log(err);
        setError("Failed Remove Sponsorship");
      });
  };

 
  return (
    <>
      <Card
        style={{
          border: "none",
          boxShadow: "0px 0px 5px 0px rgba(0, 0, 0, 0.25)",
        }}
      >
        <Card.Body>
          <BannerImage url="/img/CYN_Cynorix.jpg" />
          <h4 className="mt-3">
            Cancel Sponsorship
          </h4>
          <hr className="mt-0" />
          {!loading && (
            <Row>
                <Col>
                <Card.Text className="ml-2">
                    {"You are currently being sponsored by "} <b>{sponsorEmail}</b> {". Click the button below to cancel your sponsorship."} 
                </Card.Text>
                {error && (
                    <Alert
                    variant="danger"
                    className="d-flex align-items-center p-2"
                    >
                    <span className="me-auto">{error}</span>
                    </Alert>
                )}
                {success && <Alert variant="success">{success}</Alert>}
                <LoadingButton
                    style={{
                    marginRight: "10px",
                    width: "100%",
                    }}
                    className="w-100 mb-1"
                    onClick={() => {removeSponsorship()}}
                >
                    Cancel Sponsorship
                </LoadingButton>
                </Col>
            </Row>
          )}
        </Card.Body>
      </Card>
    </>
  );
}
