import React, { useEffect, useState } from "react";
import {
  Button,
  Modal,
  Card,
  Col,
  Stack,
  Row,
  Navbar,
  Container,
} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import check from "../assets/icons/svg/check.png";
import axios from "axios";
import BannerImage from "./BannerImage";

export default function ShowServiceClass(props) {
  const [cookies] = useCookies(["name"]);
  const [freeTrials, setFreeTrials] = useState({});
  const [trialId, setTrialId] = useState("");
  const [message, setMessage] = useState("");
  const [showModal, setShowModal] = useState(false);

  const [selectedTrialId, setSelectedTrialId] = useState("");
  const firebaseAddress = process.env.REACT_APP_SERVER_URL;

  ////////
  const [show, setShow] = useState(false);
  const navigate = useNavigate();
  const handleClose = () => navigate("/service-usage-info");
  const handleShow = () => {
    setShow(true);
  };



  const subFreetrial = () => {
    axios
      .post(`${firebaseAddress}subFreetrial`, {
        userId: props.user.id,
        userEmail: props.user.email,
        freetrial_id: trialId,
        userName: props.user.name,
      })
      .then((res) => {
        handleShow();
        setMessage("You have successfuly subscribed to our free trial!");
      })
      .catch((err) => {});
  };

  useEffect(() => {
    const getFreeTrialList = () => {
      axios
          .get(`${firebaseAddress}getFreeTrialList`, {})
          .then((res) => {
            setFreeTrials(res.data.data);
          })
          .catch((err) => {});
    };

    const check_freetrialEligible = () => {
      axios
          .post(`${firebaseAddress}checkFreetrialEligible`, {
            userId: props.user.id,
          })
          .then((res) => {
            if (res.data.data.freetrial !== "eligible") {
              setShowModal(true);
            }
          })
          .catch((err) => {});
    };

    check_freetrialEligible();
    getFreeTrialList();
  }, [firebaseAddress, props.user.id]);

  useEffect(() => {
    Object.entries(freeTrials).forEach(([key, value]) => {
      setTrialId(key);
    });
  }, [freeTrials]);

  useEffect(() => {}, [props]);

  function generateProducts() {
    return Object.entries(freeTrials).map(([key, value]) => (
      <Col sm={12} md={6} className={"mb-2"} key={key}>
        <Card
          style={{
            boxShadow:
              key === selectedTrialId
                ? "0px 0px 5px 0px rgba(0, 0, 0, 0.25)"
                : "",
          }}
        >
          <Card.Body className="pt-4 pb-0">
            <Card.Subtitle className="text-uppercase small text-center">
              <b>{value.freetrial_name}</b>
            </Card.Subtitle>
          </Card.Body>
          <hr />
          <Card.Body>
            {/* Plan Options */}
            <Stack className="align-items-start mb-4" gap={3}>
              <span>
                <img className="icon mx-1" src={check} alt="check" />
                Number of Files that can be shared:{" "}
                <b>{value.attributes.file_limit}</b>
              </span>
              <span>
                <img className="icon mx-1" src={check} alt="check" />
                Maximum Number of files that can be stored:{" "}
                <b>{value.attributes.file_max_amount}</b>
              </span>
              <span>
                <img className="icon mx-1" src={check} alt="check" />
                SMS
              </span>
              <span>
                <img className="icon mx-1" src={check} alt="check" />
                Email
              </span>
            </Stack>
            {/* Selection Button */}
            <Button
              style={{ width: "100%" }}
              active={key === selectedTrialId}
              onClick={() => {
                if (key === selectedTrialId) {
                  // allow user to deselect a plan
                  setSelectedTrialId("");
                } else {
                  setSelectedTrialId(key);
                }
              }}
            >
              {key === selectedTrialId ? "Deselect" : "Select"}
            </Button>
          </Card.Body>
        </Card>
      </Col>
    ));
  }

  return (
    <Card
      body
      className="pb-0"
      style={{
        border: "none",
        boxShadow: "0px 0px 5px 0px rgba(0, 0, 0, 0.25)",
      }}
    >
      <Modal show={showModal} dialogClassName="modal-40w">
        <Modal.Header
          style={{
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Modal.Title>Error</Modal.Title>
        </Modal.Header>
        <Modal.Body>You are not eligible for our free trials.</Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => {
              navigate("/new");
            }}
          >
            OK
          </Button>
        </Modal.Footer>
      </Modal>
      <BannerImage />
      <h4 className="mt-3">
        <b>Select a Free Trial</b>
      </h4>
      <hr className="mt-0" />
      <Row className="justify-content-center">{generateProducts()}</Row>
      <Navbar sticky="bottom">
        <Container
          className="p-3 mb-0 bg-white rounded"
          style={{
            boxShadow: "0px 0px 15px 5px rgba(0, 0, 0, 0.25)",
          }}
        >
          {selectedTrialId !== "" ? (
            <>
              <Navbar.Brand>
                {freeTrials[selectedTrialId].freetrial_name}
              </Navbar.Brand>
            </>
          ) : (
            <Navbar.Brand>No Trial Selected</Navbar.Brand>
          )}
          <Stack direction="horizontal">
            <Button
              className="ms-auto me-2"
              variant="secondary"
              onClick={() => {
                navigate(-1);
              }}
            >
              Back
            </Button>
            <Button disabled={selectedTrialId === ""} onClick={subFreetrial}>
              Try Subscription Tier
            </Button>
          </Stack>
        </Container>
      </Navbar>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {message}
          {/* I have added cookies too see what would happen if there already exist */}
          {/*{cookies.name !== "newName" && FPP === 0 && (
            <div>
              Click confirm to select the free trial.
              <strong>{" " + serviceName}</strong>
            </div>
          )}
          {cookies.name !== "newName" && FPP === 1 && (
            <div>
              If you want to switch your subscription tier, please click the
              confirm button to continue. After confirmation, your current
              subscription tier will be switched to
              <strong>{" " + serviceName}</strong> at the beginning of next
              month.
            </div>
          )}*/}
          {cookies.name === "newName" &&  (
            <div>
              You have recently changed your subscription status. Please try
              after one hour.
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
    </Card>
  );
}
