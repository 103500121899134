import React, { useState, useEffect } from "react";
import {
  Form,
  Button,
  Card,
  Row,
  Col,
  Alert,
  Modal,
} from "react-bootstrap";
import { useNavigate, useLocation } from "react-router-dom";
import "./App.css";
import axios from "axios";
import BannerImage from "./BannerImage";

const firebaseAddress = process.env.REACT_APP_SERVER_URL;

export default function EditClass() {
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [serviceClassName, setServiceClassName] = useState("");
  const [billingPeriod, setBillingPeriod] = useState("month");
  const [basicRate, setBasicRate] = useState(0);
  const [extraRate, setExtraRate] = useState(0);
  const [extraFileRate, setExtraFileRate] = useState(0);
  const [fileLimit, setFileLimit] = useState(0);
  const [fileMaxAmount, setFileMaxAmount] = useState(0);
  const [currency, setCurrency] = useState("CAD");
  const navigate = useNavigate();

  ////////
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  ////////

  const data = useLocation();
  const classId = data.state.id;



  useEffect(() => {
    const setProduct = () => {
      // Post to server retrieve questions from database
      axios
          .get(`${firebaseAddress}getproductList`, {})
          .then((res) => {
            const products = res.data.data;
            for (const product in products) {
              if (product === classId) {
                const curProduct = products[product];
                setServiceClassName(curProduct.product_name);
                setBillingPeriod(curProduct.billing_period);
                setBasicRate(curProduct.price);
                setCurrency(curProduct.currency);
                setExtraRate(curProduct.extra_rate);
                setExtraFileRate(curProduct.extra_file_rate);
                setFileLimit(curProduct.attributes.file_limit);
                setFileMaxAmount(curProduct.attributes.file_max_amount);
              }
            }
          })
          .catch((err) => {});
    };

    setProduct();
  }, [classId]);

  //Handle Create Class
  const handleCreateClass = async (e) => {
    e.preventDefault();
    try {
      setError("");
      await editClass();
      // no error
      setSuccess("Edited class successfully");
      setTimeout(() => {
        setSuccess("");
        navigate("/maintenance-dashboard");
      }, 10000);
    } catch {
      setError("Failed to edit class");
      setTimeout(() => {
        setError("");
      }, 1500);
    }
  };

  const handleRemove = async (e) => {
    e.preventDefault();
    try {
      setError("");
      await removeClass();
      // no error
      setSuccess("Removed class successfully");
      setTimeout(() => {
        setSuccess("");
        navigate("/maintenance-dashboard");
      }, 1500);
    } catch {
      setError("Failed to remove class");
      setTimeout(() => {
        setError("");
      }, 1500);
    }
  };

  const validateInput = (value) => {
    if (parseFloat(value)) {
      return true;
    } else {
      return false;
    }
  };

  const currencyFormat = (value) => {
    return Math.floor(parseFloat(value) * 100) / 100;
  };

  const editClass = async () => {
    // do input validation
    if (!validateInput(extraRate) || currencyFormat(extraRate) * 50 < 1) {
      alert("Extra share rate must be a dollar amount greater than 2 cents.");
      throw Error();
    }
    if (!validateInput(basicRate) || currencyFormat(basicRate) < 1) {
      alert("Rate per month must be a dollar amount greater than 1 dollar.");
      throw Error();
    }
    if (
      !validateInput(extraFileRate) ||
      currencyFormat(extraFileRate) * 50 < 1
    ) {
      alert("Extra file rate must be a dollar amount greater than 2 cents.");
      throw Error();
    }

    const product = {
      id: classId,
      name: serviceClassName,
      billing_period: billingPeriod,
      price: currencyFormat(basicRate),
      extra_rate: currencyFormat(extraRate),
      extra_file_rate: currencyFormat(extraFileRate),
      currency: currency,
      file_max_amount: parseInt(fileMaxAmount),
      file_limit: parseInt(fileLimit),
    };

    console.log(product);
    await axios
      .post(`${firebaseAddress}updateProductMaintenance`, {
        product,
      })
      .then((res) => {})
      .catch((err) => {
        throw err;
      });
  };

  const removeClass = async () => {
    const product = {
      id: classId,
    };
    await axios
      .post(`${firebaseAddress}removeProductMaintenance`, {
        product,
      })
      .then((response) => {})
      .catch((err) => {
        throw err;
      });
  };

  return (
    <>
      <Card
        style={{
          border: "none",
          boxShadow: "0px 0px 5px 0px rgba(0, 0, 0, 0.25)",
        }}
      >
        <Card.Body>
          <BannerImage />
          <h4 className="mt-3">
            <b>Edit Subscription Tiers</b>
          </h4>
          <hr className="mt-0" />
          <Row>
            <Col>
              <Form onSubmit={handleCreateClass}>
                <Form.Group id="subscription_name" className="mb-3">
                  <Row>
                    <Col
                      xs={12}
                      lg={2}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        padding: 0,
                      }}
                      className={`mb-2 mb-lg-0 justify-content-center justify-content-lg-end`}
                    >
                      Subscription Name
                    </Col>
                    <Col xs={12} lg={9}>
                      <Form.Control
                        type="text"
                        required
                        value={serviceClassName}
                        onChange={(event) =>
                          setServiceClassName(event.target.value)
                        }
                      />
                    </Col>
                  </Row>
                </Form.Group>

                <Form.Group id="billing_period" className="mb-3">
                  <Row>
                    <Col
                      xs={12}
                      lg={2}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        padding: 0,
                      }}
                      className={`mb-2 mb-lg-0 justify-content-center justify-content-lg-end`}
                    >
                      Billing Period
                    </Col>
                    <Col xs={12} lg={10}>
                      <Form.Select
                        value={billingPeriod}
                        onChange={(event) =>
                          setBillingPeriod(event.target.value)
                        }
                      >
                        <option value={"month"}>1 Month</option>
                        <option value={"year"}>1 Year</option>
                      </Form.Select>
                    </Col>
                  </Row>
                </Form.Group>

                <Form.Group id="basic_rate" className="mb-3">
                  <Row>
                    <Col
                      xs={12}
                      lg={2}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        padding: 0,
                      }}
                      className={`mb-2 mb-lg-0 justify-content-center justify-content-lg-end`}
                    >
                      Basic Rate
                    </Col>
                    <Col xs={12} lg={5}>
                      <Form.Control
                        // type="number"
                        // min={0}
                        value={basicRate}
                        onChange={(event) => setBasicRate(event.target.value)}
                        required
                      />
                    </Col>
                    <Col xs={12} lg={5}>
                      <Form.Select
                        value={currency}
                        onChange={(event) => {
                          setCurrency(event.target.value);
                        }}
                      >
                        <option value={"CAD"}>CAD</option>
                        <option value={"USD"}>USD</option>
                      </Form.Select>
                    </Col>
                  </Row>
                </Form.Group>
                <Form.Group id="file_restrictions" className="mb-3">
                  <Row>
                    <Col
                      xs={12}
                      lg={2}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        padding: 0,
                      }}
                      className={`mb-2 mb-lg-0 justify-content-center justify-content-lg-end`}
                    >
                      File Limit
                    </Col>
                    <Col xs={12} lg={10}>
                      <Form.Control
                        min={0}
                        type="number"
                        required
                        value={fileLimit}
                        onChange={(event) => {
                          event.preventDefault();
                          setFileLimit(event.target.value);
                        }}
                      />
                    </Col>
                  </Row>
                </Form.Group>
                <Form.Group id="file_restrictions" className="mb-3">
                  <Row>
                    <Col
                      xs={12}
                      lg={2}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        padding: 0,
                      }}
                      className={`mb-2 mb-lg-0 justify-content-center justify-content-lg-end`}
                    >
                      File Max Amount
                    </Col>
                    <Col xs={12} lg={10}>
                      <Form.Control
                        min={0}
                        type="number"
                        value={fileMaxAmount}
                        required
                        onChange={(event) => {
                          event.preventDefault();
                          setFileMaxAmount(event.target.value);
                        }}
                      />
                    </Col>
                  </Row>
                </Form.Group>
                <Form.Group id="file_restrictions" className="mb-3">
                  <Row>
                    <Col
                      xs={12}
                      lg={2}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        padding: 0,
                      }}
                      className={`mb-2 mb-lg-0 justify-content-center justify-content-lg-end`}
                    >
                      Extra Share Rate
                    </Col>
                    <Col xs={12} lg={10}>
                      <Form.Control
                        // min={0}
                        // type="number"
                        value={extraRate}
                        required
                        onChange={(event) => {
                          event.preventDefault();
                          setExtraRate(event.target.value);
                        }}
                      />
                    </Col>
                  </Row>
                </Form.Group>
                <Form.Group id="file_restrictions" className="mb-3">
                  <Row>
                    <Col
                      xs={12}
                      lg={2}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        padding: 0,
                      }}
                      className={`mb-2 mb-lg-0 justify-content-center justify-content-lg-end`}
                    >
                      Extra File Rate
                    </Col>
                    <Col xs={12} lg={10}>
                      <Form.Control
                        // min={0}
                        // type='number'
                        value={extraFileRate}
                        required
                        onChange={(event) => {
                          event.preventDefault();
                          setExtraFileRate(event.target.value);
                        }}
                      />
                    </Col>
                  </Row>
                </Form.Group>

                <Form.Group className="mb-3 text-start">
                  {success && <Alert variant="success">{success}</Alert>}
                  {error && <Alert variant="danger">{error}</Alert>}

                  <Button
                    className="mt-3 w-100 btn btn-secondary"
                    onClick={handleShow}
                    variant="danger"
                    style={{
                      backgroundColor: "#800000",
                      border: "none",
                    }}
                  >
                    Remove Class
                  </Button>

                  <Button
                    className="mt-3 w-100 btn btn-secondary"
                    disabled={success}
                    type="submit"
                    variant="danger"
                    style={{
                      backgroundColor: "#800000",
                      border: "none",
                    }}
                  >
                    Confirm Edit Class
                  </Button>
                </Form.Group>
              </Form>
            </Col>
          </Row>
        </Card.Body>
      </Card>

      {/* {!hasAccess && <h4>Access denied.</h4>} */}

      <Modal show={show} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          If you want to remove this class, please click confirm button to
          continue.
        </Modal.Body>
        {success && <Alert variant="success">{success}</Alert>}
        {error && <Alert variant="danger">{error}</Alert>}
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleRemove}>
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
