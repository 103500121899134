import firebase from "firebase/compat/app";
import "firebase/compat/auth";

const firebaseConfig = {
  apiKey: "AIzaSyAsI9Y7HAJAA-LaWBv2SwO_YskLLUhrGqU",
  authDomain: "cynorix-sfs-onedrive.firebaseapp.com",
  databaseURL: "https://cynorix-sfs-onedrive-default-rtdb.firebaseio.com",
  projectId: "cynorix-sfs-onedrive",
  storageBucket: "cynorix-sfs-onedrive.appspot.com",
  messagingSenderId: "24870292513",
  appId: "1:24870292513:web:dd77c065cd79bb5b4da760",
  measurementId: "G-XP4DLTX9JH",
};

const app = firebase.initializeApp(firebaseConfig);

export const auth = app.auth();
export default app;
