import React, { useState, useEffect } from "react";
import { Button, Card, Row, Col } from "react-bootstrap";
import Axios from "axios";
import { useNavigate } from "react-router-dom";
import BannerImage from "./BannerImage";

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

export default function UpgradeService(props) {
  const [isSponsored, setIsSponsored] = useState();
  const [hasSponsorships, setHasSponsorships] = useState(true);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const renderAccessStatus = async () => {
      try {
        let user_id = props.user.id;
        await Axios.post(`${SERVER_URL}getUserSponsorship`, {
          user_id,
        }).then((res) => {
          console.log(res.data.data);
          setHasSponsorships(
            Object.hasOwn(res.data.data, "list_of_sponsorships")
          );
          setIsSponsored(Object.hasOwn(res.data.data, "sponsor_id"));
        });
      } catch (e) {
        alert("Error Rendering Page");
      }
      setLoading(false);
    };

    (async () => {
      await renderAccessStatus();
    })();
  }, [props.user.id]);

  const renderAccessStatus = async () => {
    try {
      let user_id = props.user.id;
      await Axios.post(`${SERVER_URL}getUserSponsorship`, {
        user_id,
      }).then((res) => {
        // console.log(res.data.data)
        setHasSponsorships(
          Object.hasOwn(res.data.data, "list_of_sponsorships")
        );
        setIsSponsored(Object.hasOwn(res.data.data, "sponsor_id"));
      });
    } catch (e) {
      alert("Error Rendering Page");
    }
    setLoading(false);
  };
  const navigate = useNavigate();

  return (
    <>
      <Card
        body
        style={{
          border: "none",
          boxShadow: "0px 0px 5px 0px rgba(0, 0, 0, 0.25)",
        }}
      >
        <BannerImage url="/img/CYN_Cynorix.jpg" />
        <h4 className="mt-3">
          You Can Request A Sponsorship Or Proceed To Our Subscriptions
        </h4>
        {!loading && (
          <Row>
            <Col>
              <Button
                className="w-100 mt-3"
                onClick={() => {
                  navigate("/select-sponsor");
                }}
                disabled={hasSponsorships || isSponsored}
              >
                Request Sponsorship
              </Button>
            </Col>
            <Col>
              <Button
                className="w-100 mt-3"
                onClick={() => {
                  navigate("/show-service-class");
                }}
              >
                Show Subscription Tiers
              </Button>
            </Col>
          </Row>
        )}
      </Card>
    </>
  );
}
