import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import { createContext, useEffect, useRef, useState } from "react";
import { Alert, Col, Collapse, Form, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { serverUrl, verifyPassword } from "../util/SecureCommunication";
import FeedbackAlert from "./FeedbackAlert";
import LoadingButton from "./LoadingButton";

export const PasswordContext = createContext();

// verified states
const VERIFIED_STATUS = {
  NOT_VERIFIED: 0,
  PWD_VERIFIED: 1,
  OTP_VERIFIED: 2,
};

// OTP time allowed to verify
const SECONDS_ALLOWED_OTP = 185;

// OTP status
const OTP_STATUS = {
  NOT_SENT: 0,
  SENDING: 1,
  SENT: 2,
};

// requires:
// - userid (mandatory): Current user id. MUST BE DEFINED.
// - setPassword (optional): Pass on password value once verified to
//      parent component.
// - requiresOTP (optional): Do you want to ask for a second factor of auth
export default function PasswordWrapper(props) {
  // ** Hooks
  const navigate = useNavigate();
  const [verifiedStatus, setVerifiedStatus] = useState(
    VERIFIED_STATUS.NOT_VERIFIED
  );
  const [showPwd, setShowPwd] = useState(false);
  const [password, setPassword] = useState("");
  const [feedback, setFeedback] = useState(null);
  // if the component this wraps requires OTP verification
  const [requiresOTP, setRequiresOTP] = useState(false);
  useEffect(() => {
    if (props.requiresOTP) {
      setRequiresOTP(true);
    } else {
      setRequiresOTP(false);
    }
  }, [props.requiresOTP]);
  const verifiedThreshold = requiresOTP
    ? VERIFIED_STATUS.OTP_VERIFIED
    : VERIFIED_STATUS.PWD_VERIFIED;
  const showOTPFields =
    requiresOTP && verifiedStatus === VERIFIED_STATUS.PWD_VERIFIED;

  // OTP verification helper states
  const [otpTimeLeft, setOtpTimeLeft] = useState(SECONDS_ALLOWED_OTP);
  const [otpStatus, setOtpStatus] = useState(OTP_STATUS.NOT_SENT);
  const activeTimer = useRef();
  useEffect(() => {
    if (activeTimer.current) {
      clearInterval(activeTimer.current);
    }

    if (otpStatus === OTP_STATUS.SENT) {
      activeTimer.current = setInterval(() => {
        setOtpTimeLeft((t) => t - 1);
      }, 1000);
    }

    return () => {
      clearInterval(activeTimer.current);
    };
  }, [otpStatus]);

  useEffect(() => {
    if (otpTimeLeft === 0) {
      clearInterval(activeTimer.current);
      setOtpStatus(OTP_STATUS.NOT_SENT);
      setFeedback({
        variant: "warning",
        message: "OTP Expired, please resend code to continue",
      });
    } else if (verifiedStatus >= VERIFIED_STATUS.OTP_VERIFIED) {
      clearInterval(activeTimer.current);
    }
  }, [otpTimeLeft, verifiedStatus]);

  // ** Helper Functions
  function sendOTP() {
    setOtpStatus(OTP_STATUS.SENDING);
    setOtpTimeLeft(SECONDS_ALLOWED_OTP);
    axios
      .post(
        `${serverUrl}requestSMSOTP`,
        { userId: props.user.id },
        { headers: { "Content-Type": "application/json" } }
      )
      .then((res) => {
        setOtpStatus(OTP_STATUS.SENT);
      })
      .catch((err) => {
        setOtpStatus(OTP_STATUS.NOT_SENT);
        setFeedback({
          loading: false,
          message: `An error occured while sending an OTP to your phone.`,
          variant: "danger",
        });
      });
  }

  async function onSubmitPassword(e) {
    // quick input validation
    if (password === "") {
      setFeedback({
        message: "Please fill in your password",
        variant: "warning",
      });
      return;
    }
    // verify password
    try {
      setFeedback({
        message: "Verifying password..",
        loading: true,
        variant: "info",
      });
      const isPwdVerified = await verifyPassword(
        props.user.id,
        password,
        () => {
          navigate("/unlock");
        }
      );
      if (isPwdVerified) {
        setVerifiedStatus(VERIFIED_STATUS.PWD_VERIFIED);
        if (props.setPassword) {
          props.setPassword(password);
        }
        setFeedback(null);
      } else {
        setFeedback({
          message: "Incorrect Password",
          variant: "danger",
        });
      }
    } catch (e) {
      setFeedback({
        message: "Something went wrong while verifying your password.",
        variant: "danger",
      });
    }
  }

  function onSubmitOTP(e) {
    // quick input validation
    if (e.target.otp.value === "") {
      setFeedback({
        message: "Please fill in your OTP",
        variant: "warning",
      });
      return;
    }
    setFeedback({
      loading: true,
      message: "Verifying OTP...",
      variant: "info",
    });
    axios
      .post(`${serverUrl}verifySMSOTP`, {
        userId: props.user.id,
        otp: e.target.otp.value,
      })
      .then((res) => {
        if (res.status === 200) {
          // verified
          setVerifiedStatus(VERIFIED_STATUS.OTP_VERIFIED);
        } else {
          setFeedback({
            message: "Something went wrong.",
            variant: "danger",
          });
        }
      })
      .catch((error) => {
        if (error.response.data.status === 500) {
          setFeedback({
            message: "Wrong OTP",
            variant: "danger",
          });
        } else {
          setFeedback({
            message: "Something went wrong.",
            variant: "danger",
          });
        }
      });
  }

  if (verifiedStatus >= verifiedThreshold) {
    return props.children;
  }

  return (
    <>
      <p>
        {!requiresOTP
          ? "This page requires you to provide your password."
          : `This page requires you to provide your password, as well 
          as a one-time password sent to your mobile phone.`}
      </p>
      <Form
        noValidate
        onSubmit={(e) => {
          // prevent page from refreshing
          e.preventDefault();

          switch (verifiedStatus) {
            case VERIFIED_STATUS.PWD_VERIFIED:
              onSubmitOTP(e);
              break;
            case VERIFIED_STATUS.NOT_VERIFIED:
              onSubmitPassword(e);
              break;
            default:
              break;
          }
        }}
      >
        <Form.Group className="mb-3 row">
          <Form.Label column className="text-center text-lg-end px-0">
            Password
          </Form.Label>
          <Col xs={12} lg={10}>
            <div className="pass-wrapper mb-0">
              <Form.Control
                type={showPwd ? "text" : "password"}
                autoComplete="new-password"
                name="password"
                value={password}
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
                required
                disabled={verifiedStatus >= VERIFIED_STATUS.PWD_VERIFIED}
              />
              <div
                className="test"
                onClick={() => {
                  setShowPwd(!showPwd);
                }}
              >
                <FontAwesomeIcon
                  icon={showPwd ? "eye-slash" : "eye"}
                  onClick={() => {
                    setShowPwd(!showPwd);
                  }}
                />
              </div>
            </div>
          </Col>
        </Form.Group>
        <Collapse in={showOTPFields} className="my-0">
          <div>
            <hr />
            <Form.Group className="mb-3 row">
              <Form.Label column className="text-center text-lg-end px-0">
                SMS Confirmation
              </Form.Label>
              <Col xs={12} lg={9} xl={10}>
                <Row>
                  <Col>
                    <Form.Control name="otp" />
                  </Col>
                  <Col xs="auto" className="ps-0">
                    <LoadingButton
                      loading={otpStatus === OTP_STATUS.SENDING}
                      disabled={otpStatus !== OTP_STATUS.NOT_SENT}
                      onClick={sendOTP}
                    >
                      Send Code
                    </LoadingButton>
                  </Col>
                </Row>
              </Col>
            </Form.Group>
          </div>
        </Collapse>
        <LoadingButton
          type="submit"
          loading={feedback && feedback.loading}
          disabled={feedback && feedback.loading}
          className="w-100"
        >
          Next
        </LoadingButton>
      </Form>
      {otpStatus === OTP_STATUS.SENT ? (
        <Alert variant="info" className="mt-2 mb-0">
          A one time password has been sent to your phone. Time remaining:{" "}
          {Math.floor(otpTimeLeft / 60) +
            ":" +
            (otpTimeLeft % 60 < 10 ? "0" : "") +
            (otpTimeLeft % 60)}
        </Alert>
      ) : null}
      <FeedbackAlert feedback={feedback} className="mb-0 mt-2" />
    </>
  );
}
