import axios from "axios";
import { useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Collapse,
  Form,
  Nav,
  Row,
  Spinner,
} from "react-bootstrap";
import InfoButton from "./InfoButton.js";
import { useNavigate } from "react-router-dom";
import BannerImage from "./BannerImage";
import FeedbackAlert from "./FeedbackAlert";
import PasswordInput from "./PasswordInput";
import PhoneCountryCodeDropdown from "./PhoneCountryCodeDropdown";
import { serverUrl } from "./../util/SecureCommunication";
import {
  formCheck,
  generateVerification,
  getNewEncryptedPass,
} from "../functions/encoding";
import useOTPTimer, { OTP_STATUS } from "../util/useOTPTimer";

// steps
export const ACCOUNT_STEPS = {
  LOADING: -1,
  OAUTH: 0,
  SET_PASSWORD: 1,
  SET_PHONE_NUMBER: 2,
  SET_SECURITY_QUESTIONS: 3,
  ACCOUNT_CREATED: 4,
};

const navText = [
  {
    text: "OAuth",
    status: ACCOUNT_STEPS.OAUTH,
    disabled: (status) => true,
  },
  {
    text: "Password",
    status: ACCOUNT_STEPS.SET_PASSWORD,
    disabled: (status) => status !== ACCOUNT_STEPS.SET_PHONE_NUMBER,
  },
  {
    text: "Phone Number",
    status: ACCOUNT_STEPS.SET_PHONE_NUMBER,
    disabled: (status) => true,
  },
  {
    text: "Security Questions",
    status: ACCOUNT_STEPS.SET_SECURITY_QUESTIONS,
    disabled: (status) => true,
  },
];

// improve this so that you can extract more info from this
export function validateCreatePassword(
  pass,
  confirmPass,
  pushFeedback = () => {}
) {
  pushFeedback(null);

  // check if password is not blank
  if (pass === "") {
    pushFeedback({
      variant: "warning",
      message: "Please fill in your password",
    });
    return false;
  }

  // check if password and confirm password match
  if (pass !== confirmPass) {
    pushFeedback({
      variant: "danger",
      message: "Passwords do not match",
    });
    return false;
  }

  // check if password meets regex requirements
  // this can be improved, maybe detect what is missing as well
  const MIN_PW_LENGTH = 8;
  const regex = new RegExp(
    `^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)[\\S\\W\\D]{${MIN_PW_LENGTH},}$`,
    "g"
  );
  if (!regex.test(pass)) {
    pushFeedback({
      variant: "warning",
      message: `Your password must be at least ${MIN_PW_LENGTH} characters, 
        contain a lower case letter, an upper case letter, 
        and at least one digit.`,
    });
    return false;
  }

  return true;
}

export function validateCreateSecurityQuestions(
  questions,
  answers,
  pushFeedback = () => {}
) {
  if (questions.some((x) => x === "") || answers.some((x) => x === "")) {
    pushFeedback({
      variant: "danger",
      message: "Enter all the required security questions and answers",
    });
    return false;
  }

  return true;
}

export default function CreateAccount(props) {
  // ** hooks
  const navigate = useNavigate();

  // ** states
  const [feedback, setFeedback] = useState(null);
  const [signupStatus, setSignupStatus] = useState(ACCOUNT_STEPS.LOADING);
  // password states
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  // phone number states
  const [twilioID, setTwilioID] = useState("");
  const [countryCode, setCountryCode] = useState(1);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [otpCode, setOtpCode] = useState("");
  // security questions states
  const SECURITY_QUESTION_COUNT = 5;
  const [questions, setQuestions] = useState(
    Array(SECURITY_QUESTION_COUNT).fill("")
  );
  const [answers, setAnswers] = useState(
    Array(SECURITY_QUESTION_COUNT).fill("")
  );

  // enforcing the url based on signup status
  useEffect(() => {
    setSignupStatus(props.isSignedUp);
    if (props.isSignedUp === ACCOUNT_STEPS.ACCOUNT_CREATED) {
      navigate("/new");
    }
  }, [props.isSignedUp, navigate]);

  // OTP verification helper states
  const { otpStatus, setOtpStatus, timerFeedback } =
    useOTPTimer(setFeedback);
  function requestOTP() {
    setOtpStatus(OTP_STATUS.SENDING);
    setFeedback({
      variant: "info",
      loading: true,
      message: "Sending OTP to your phone...",
    });
    // call register OTP
    axios
      .post(`${serverUrl}requestTwilioRegistration`, {
        countryCode,
        phoneNumber,
        userEmail: props.user.email,
      })
      .then((res) => {
        setOtpStatus(OTP_STATUS.SENT);
        // startTimer();
        setFeedback(null);
        setTwilioID(res.data.data.twilioID);
      })
      .catch((err) => {
        setOtpStatus(OTP_STATUS.NOT_SENT);
        setFeedback({
          variant: "danger",
          message:
            "An error occurred while sending phone verification. Please try again.",
        });
      });
  }

  // ** functions
  function onSubmitPassword(e) {
    // prevent form submit from refreshing page
    e.preventDefault();

    // validate password
    if (validateCreatePassword(password, confirmPassword, setFeedback)) {
      setSignupStatus(ACCOUNT_STEPS.SET_PHONE_NUMBER);
    }
  }

  function onSubmitOTP(e) {
    // prevent form submit from refreshing page
    e.preventDefault();

    // if twilio id is not set, it means the phone number is not registered.
    if (twilioID === "") {
      // some input validation for phone number
      if (phoneNumber === "") {
        setFeedback({
          message: "Please fill in your phone number",
          variant: "warning",
        });
        return;
      }
      requestOTP();
    } else {
      if (otpCode === "") {
        setFeedback({
          message: "Please fill in your OTP",
          variant: "warning",
        });
        return;
      }
      setFeedback({
        variant: "info",
        loading: true,
        message: "Verifying...",
      });
      // get user id
      const userId = props.user.id;

      // call formCheck on password+userId
      axios
        .post(`${serverUrl}addHashedPass`, {
          newUserId: userId,
          check: formCheck(password + userId),
          otp: otpCode,
          twilioID: twilioID,
          userEmail: props.user.email,
          userName: props.user.name,
        })
        .then((res) => {
          setSignupStatus(ACCOUNT_STEPS.SET_SECURITY_QUESTIONS);
          setFeedback(null);
          setOtpStatus(OTP_STATUS.NOT_SENT);
        })
        .catch((err) => {
          setFeedback(null);
          const errMsg = err.response.data.errormsg;

          // provide more helpful errors TODO
          const invalidKeyMsg =
            "error decoding request (possibly due to invalid session key)";
          if (errMsg === invalidKeyMsg) {
          } else if (errMsg === "User password already set!") {
            setFeedback({
              variant: "warning",
              message: "You have already set your password for this account.",
            });
          } else if (errMsg === "Twilio Verification Failed!") {
            setFeedback({
              variant: "warning",
              message:
                "You have submitted the wrong OTP. Please recheck and resubmit.",
            });
          } else {
            setFeedback({
              variant: "warning",
              message: `An error occured while adding the password to our server. 
                       Please try again.`,
            });
          }
        });
    }
  }

  function onSubmitSecurityQuestions(e) {
    e.preventDefault();
    setFeedback({
      variant: "info",
      loading: true,
      message: "Submitting...",
    });

    const [questionsTrim, answersTrim] = [questions, answers].map((val) =>
      val.map((x) => x.trim())
    );

    // validation
    if (
      !validateCreateSecurityQuestions(questionsTrim, answersTrim, setFeedback)
    ) {
      return;
    }

    // api call
    axios
      .post(
        `${serverUrl}submitQuestions`,
        {
          userId: props.user.id,
          questions: questionsTrim,
          password: getNewEncryptedPass(
            questionsTrim.join(""),
            answersTrim.join(""),
            password
          ),
          verification: generateVerification(answersTrim.join("")),
          otp: "registering",
        },
        { headers: { "Content-Type": "application/json" } }
      )
      .then((res) => {
        if (res.status === 200) {
          // alert("Questions and answers updated successfully!");
          setFeedback({
            message:
              "Questions and answers updated successfully! Redirecting...",
            variant: "success",
            loading: true,
          });
          setTimeout(() => {
            props.setIsSignedUp(ACCOUNT_STEPS.ACCOUNT_CREATED);
          }, 2000);
        } else {
          // alert("Oops, something went wrong");
          setFeedback({
            message: "Something went wrong",
            variant: "danger",
          });
        }
      })
      .catch((err) => {
        setFeedback({
          message: "Something went wrong",
          variant: "danger",
        });
      });
  }

  // this determines what part of the signup form the user sees
  function generateAccountCreationPages() {
    // helpful constants
    const isLoading = feedback && feedback.loading;

    // label width will be for determining how wide the labels should be,
    // based on the longest string in that block.
    let labelWidth;
    // based on the location hash (in the url), the page will display
    // a different part of the signup form.
    switch (signupStatus) {
      case ACCOUNT_STEPS.OAUTH:
        return (
          <div>
            <p>Click the button below to add Cynorix to your OneDrive</p>
            <Button
              className="w-100"
              onClick={(e) => {
                props.signIn();
              }}
            >
              Sign Up With Microsoft
            </Button>
          </div>
        );
      case ACCOUNT_STEPS.SET_PASSWORD:
        labelWidth = `${"Confirm Password".length * 0.9}ch`;
        return (
          <div>
            <Row>
              <Col>
                <p className="d-flex">
                  Please set your password below. This will be used to encrypt
                  and decrypt all future files.
                </p>
              </Col>
              <Col xs="auto">
                <InfoButton header="Password Requirements">
                  <p className="mb-1">
                    In order for a user's password to be secure, their password
                    is required to have at least 8 characters, at least 1 letter
                    and at least 1 number.
                  </p>
                </InfoButton>
              </Col>
            </Row>
            <Form onSubmit={onSubmitPassword}>
              <Form.Group controlId="new-pwd" className="mb-2 px-2 row">
                <Col xs={12} sm={"auto"}>
                  <Form.Label column style={{ width: labelWidth }}>
                    Password
                  </Form.Label>
                </Col>
                <Col>
                  <PasswordInput
                    value={password}
                    onChange={(e) => {
                      validateCreatePassword(
                        e.target.value,
                        confirmPassword,
                        setFeedback
                      );
                      setPassword(e.target.value);
                    }}
                  />
                </Col>
              </Form.Group>
              <Form.Group controlId="confirm-pwd" className="mb-3 px-2 row">
                <Col xs={12} sm={"auto"}>
                  <Form.Label column style={{ width: labelWidth }}>
                    Confirm Password
                  </Form.Label>
                </Col>
                <Col>
                  <PasswordInput
                    value={confirmPassword}
                    onChange={(e) => {
                      validateCreatePassword(
                        password,
                        e.target.value,
                        setFeedback
                      );
                      setConfirmPassword(e.target.value);
                    }}
                  />
                </Col>
              </Form.Group>
              <Button disabled={isLoading} type="submit" className="w-100">
                Next
              </Button>
            </Form>
          </div>
        );
      case ACCOUNT_STEPS.SET_PHONE_NUMBER:
        labelWidth = `${"Confirmation Code".length * 0.9}ch`;
        return (
          <div>
            <Row>
              <Col>
                <p className="d-flex">
                  Please provide your phone number for an added layer of
                  security.
                </p>
              </Col>
              <Col xs="auto">
                <InfoButton header="SMS OTP">
                  <p className="mb-1">
                    For added security, Cynorix Secure File Sharing requires a
                    phone number for sending One Time Passwords via SMS. Users
                    will be sent temporary codes that they will have to enter as
                    secondary verification.
                  </p>
                </InfoButton>
              </Col>
            </Row>
            <Form onSubmit={onSubmitOTP}>
              <Form.Group className="mb-2 px-2 row">
                <Col xs={12} sm={"auto"}>
                  <Form.Label column style={{ width: labelWidth }}>
                    Phone Number
                  </Form.Label>
                </Col>
                <Col>
                  <PhoneCountryCodeDropdown setCountryCode={setCountryCode}>
                    <Form.Control
                      type="tel"
                      name="phoneNumber"
                      pattern={"[0-9]*"}
                      value={phoneNumber}
                      onChange={(e) => {
                        // check if the key pressed is a number.
                        if (e.target.value.search(/[^0-9]/) !== -1) {
                          setFeedback({
                            message:
                              "Please enter a phone number with numbers only.",
                            variant: "warning",
                          });
                        } else {
                          setFeedback(null);
                          setPhoneNumber(e.target.value);
                          setOtpStatus(OTP_STATUS.NOT_SENT);
                          setTwilioID("");
                        }
                      }}
                    />
                  </PhoneCountryCodeDropdown>
                </Col>
              </Form.Group>
              <Collapse in={twilioID !== ""}>
                <Form.Group className="mb-3 px-2 row">
                  <Col xs={12} sm={"auto"}>
                    <Form.Label column style={{ width: labelWidth }}>
                      Confirmation Code
                    </Form.Label>
                  </Col>
                  <Col>
                    <Row className="gx-2">
                      <Col>
                        <Form.Control
                          value={otpCode}
                          onChange={(e) => {
                            setOtpCode(e.target.value);
                          }}
                        />
                      </Col>
                      <Col xs={"auto"}>
                        <Button
                          disabled={otpStatus !== OTP_STATUS.NOT_SENT}
                          onClick={requestOTP}
                        >
                          Send Code
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                </Form.Group>
              </Collapse>
              <Button disabled={isLoading} type="submit" className="w-100">
                Submit
              </Button>
              <FeedbackAlert feedback={timerFeedback} className="mt-2 mb-0" />
            </Form>
          </div>
        );
      case ACCOUNT_STEPS.SET_SECURITY_QUESTIONS:
        labelWidth = `${"Security Question".length * 0.8}ch`;
        return (
          <div>
            <p>
              Please provide security questions and answers for added identity
              verification
            </p>
            <Form onSubmit={onSubmitSecurityQuestions}>
              {Array(SECURITY_QUESTION_COUNT)
                .fill()
                .map((_, index) => (
                  <>
                    {/* Question */}
                    <Form.Group className="mb-2 px-2 row">
                      <Col xs={12} sm={"auto"}>
                        <Form.Label column style={{ width: labelWidth }}>
                          Security Question
                        </Form.Label>
                      </Col>
                      <Col>
                        <Form.Control
                          value={questions[index]}
                          onChange={(e) => {
                            setQuestions(
                              questions.map((x, i) =>
                                index === i ? e.target.value : x
                              )
                            );
                          }}
                        />
                      </Col>
                    </Form.Group>
                    {/* Answer */}
                    <Form.Group className="mb-3 px-2 row">
                      <Col xs={12} sm={"auto"}>
                        <Form.Label column style={{ width: labelWidth }}>
                          Answer
                        </Form.Label>
                      </Col>
                      <Col>
                        <Form.Control
                          value={answers[index]}
                          onChange={(e) => {
                            setAnswers(
                              answers.map((x, i) =>
                                index === i ? e.target.value : x
                              )
                            );
                          }}
                        />
                      </Col>
                    </Form.Group>
                  </>
                ))}
              <Button disabled={isLoading} type="submit" className="w-100">
                Submit
              </Button>
            </Form>
          </div>
        );
      default:
        return (
          <div className="d-flex align-items-center">
            <Spinner size="sm" className="mx-2" />
            <span>Currently getting your account information...</span>
          </div>
        );
    }
  }

  // the base of the component
  return (
    <Card
      body
      style={{
        border: "none",
        boxShadow: "0px 0px 5px 0px rgba(0, 0, 0, 0.25)",
      }}
    >
      <BannerImage />
      <h4 className="mt-3">
        <b>Create Account</b>
      </h4>
      <hr className="mt-0" />
      <Card
        style={{
          border: "none",
          boxShadow: "0px 0px 5px 0px rgba(0, 0, 0, 0.25)",
        }}
        className="mb-3 p-2"
      >
        <Nav
          justify
          variant="pills"
          activeKey={signupStatus}
          className="justify-content-center"
        >
          {navText.map(({ text, status, disabled }) => (
            <Nav.Item>
              <Nav.Link
                eventKey={status}
                onClick={() => {
                  setSignupStatus(status);
                }}
                disabled={disabled(signupStatus, password)}
              >
                {text}
              </Nav.Link>
            </Nav.Item>
          ))}
        </Nav>
      </Card>
      {/* Content Goes here*/}
      {generateAccountCreationPages()}
      <FeedbackAlert feedback={feedback} className="mb-0 mt-3" />
    </Card>
  );
}
