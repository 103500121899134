import { useState, useEffect } from "react";
import {
  Button,
  Card,
  Col,
  Form
} from "react-bootstrap";
import "axios";
import ComponentCard from "./ComponentCard.js";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  downloadFileMicroSoft,
  sendEmailMicrosoft,
  serverUrl,
  verifyPassword,
} from "../util/SecureCommunication";
import axios from "axios";
import { createUserKey, stringToUintArray, XOR } from "../functions/encoding";
import FeedbackAlert from "./FeedbackAlert";
import { useCookies } from "react-cookie";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
library.add(faEye, faEyeSlash);

export default function Receive(props) {
  const [isPwdValid, setIsPwdValid] = useState(true);
  const [isClaimed, setIsClaimed] = useState(false);
  const [user, setUser] = useState(props.user);
  const [feedback, setFeedback] = useState(null);
  const [pwd, setPwd] = useState("");
  const [passwordShown, setPasswordShown] = useState(false);
  const togglePassword = () => {
    // When the handler is invoked
    // inverse the boolean state of passwordShown
    setPasswordShown(!passwordShown);
  };
  useEffect(() => {
    setUser(props.user);
  }, [props.user]);
  const [cookies] = useCookies(["accessToken"]);
  console.log(cookies)

  // get url query parameters
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  function assertDefined(items) {
    let notDefined = [];
    Object.entries(items).forEach(([name, value]) => {
      if (value === undefined || value === null) {
        notDefined.push(name);
      }
    });
    if (notDefined.length !== 0) {
      throw Error(`${notDefined.join(",")} are not defined.`);
    }
  }

  async function claimFile(pwd, user, ownerEmail, shareKeyRef, fileId, key) {
    // require that all fields are valid
    try {
      assertDefined({ pwd, user, ownerEmail, fileId });
    } catch (err) {
      pushFeedback({
        variant: "danger",
        message: `The URL you have accessed contains some missing information. 
                  Check the URL provided in the email notification.`,
      });
      return;
    }

    pushFeedback({
      variant: "info",
      loading: true,
      message: `Claiming file from ${ownerEmail}`,
    });
    try {
      const res = await axios.post(`${serverUrl}getEncRandKey`, {
        ownerEmail,
        shareKeyRef,
        fileId,
        userId: user.id,
      });
      const body = res.data.data;
      if (body.active) {
        // reconstruct random key used to mask owner's file key
        const key2 = stringToUintArray(key);
        const randomKey = body.key;
        const key1 = XOR(key2, randomKey);
        shareKeyRef = body.keyRef;

        // mask recipient file key with same randomKey used to mask owner key
        const hashed = createUserKey(fileId, pwd, user.id);
        const recipKey = XOR(hashed, key1);

        // make second request.
        const res2 = await axios.post(`${serverUrl}formRecipTransformedKey`, {
          ownerEmail,
          fileId,
          recipKey,
          shareKeyRef,
          recipientEmail: user.email,
          userId: user.id,
        });
        const body2 = res2.data.data;
        const fileName = body2.fileName;
        const subject = `Cynorix Secure File Sharing: ${fileName} Received`;
        const emailMsg = `
          Hello, <br><b>${user.email}</b> has successfully claimed the file
          <b>${fileName}</b>
        `;

        sendEmailMicrosoft(
          subject,
          emailMsg,
          ownerEmail,
          user,
          cookies.accessToken
        )
          .then((res) => {
            setIsClaimed(true);
            pushFeedback(null);
          })
          .catch((err) => {
            pushFeedback({
              variant: "danger",
              message: `Error emailing owner about successful file claim.`,
            });
            setIsClaimed(true);
          });
      }
    } catch (err) {
      const errMsg = err.response.data.errormsg;
      const ERR_FILE_NOT_EXIST = "file does not exist anymore";
      if (errMsg === ERR_FILE_NOT_EXIST) {
        pushFeedback({
          variant: "danger",
          message: (
            <>
              <b>Error while claiming file</b>
              <br />
              The file you are trying to claim does not exist anymore. You might
              have already claimed the file or the sender may have deleted this
              file. Please contact the sender if you suspect that the file has
              been deleted.
            </>
          ),
        });
      } else {
        pushFeedback({
          variant: "danger",
          message: `An error has occurred while claiming file.`,
        });
      }
    }
  }

  function pushFeedback(feedback) {
    setFeedback(feedback);
  }

  function validateFields(pwd) {
    return new Promise(async (resolve, reject) => {
      if (pwd === "") {
        setIsPwdValid(false);
        resolve(false);
      } else {
        try {
          pushFeedback({
            variant: "info",
            loading: true,
            message: `Verifying Password for ${user.name}`,
          });
          const isValid = await verifyPassword(user.id, pwd, () => {
            navigate("/unlock");
          });
          setIsPwdValid(isValid);
          if (!isValid) {
            pushFeedback({
              variant: "warning",
              message: `Password incorrect for user ${user.name}(${user.email}).`,
            });
          }
          resolve(isValid);
        } catch (err) {
          pushFeedback({
            variant: "danger",
            message: `An unexpected error occurred while verifying password.`,
          });
          reject(err);
        }
      }
    });
  }
  const helpContent = (
    <p className="mb-1">
      Claiming a file adds the file to the user's OneDrive and File Table. It
      also allows them to Share and Download the file.
    </p>
  );
  return (
    <ComponentCard title="Receive File" helpContent={helpContent}>
      <hr className="mt-0" />
      <p>Enter your password below to claim the file </p>
      <Form
        noValidate
        onSubmit={async (e) => {
          e.preventDefault();
          // start loader
          const isValid = await validateFields(pwd);

          if (isValid) {
            // do file claim
            const fileId = searchParams.get("fileId");
            const key = searchParams.get("key");
            let shareKeyRef = searchParams.get("keyRef");
            if (shareKeyRef === "null") {
              shareKeyRef = null;
            }
            const ownerEmail = searchParams.get("owner");

            claimFile(pwd, user, ownerEmail, shareKeyRef, fileId, key);
          } else {
            // dont do file claim
          }
        }}
      >
        <fieldset disabled={isClaimed}>
          <Form.Group controlId="receive-account-password" className="row mb-3">
            <Form.Label column>Password</Form.Label>
            <Col xs={12} sm={12} md={10}>
              <div className="pass-wrapper">
                <input
                  className="form-control"
                  type={passwordShown ? "text" : "password"}
                  name="accountPass"
                  placeholder="Password"
                  isInvalid={!isPwdValid}
                  value={pwd}
                  onChange={(e) => {
                    setPwd(e.target.value);
                  }}
                />
                <i className="test">
                  <FontAwesomeIcon
                    icon={passwordShown ? "eye-slash" : "eye"}
                    onClick={togglePassword}
                  />
                </i>
              </div>
            </Col>
          </Form.Group>
          <Button type="submit" aria-label="Claim File" className="w-100">
            Submit
          </Button>
        </fieldset>
      </Form>
      {isClaimed ? (
        <Card body bg="secondary" text="white" className="mt-2 pb-0">
          <h6 style={{ fontSize: 18 }}>
            <b>File Claimed</b>
          </h6>
          <hr className="mt-0" />
          <p>
            The file has been claimed. To access this file, please open it from
            your OneDrive using Cynorix Secure File Sharing or download it
            with the button below.
          </p>
          <Button
            disabled={feedback !== null}
            className="float-end"
            onClick={() => {
              // do the thing from open.js
              downloadFileMicroSoft(
                pwd,
                searchParams.get("fileId"),
                user,
                cookies.accessToken,
                false,
                pushFeedback,
                () => {
                  navigate("/unlock");
                }
              );
            }}
          >
            Download
          </Button>
        </Card>
      ) : null}
      <FeedbackAlert feedback={feedback} className="mt-2 mb-0" />
    </ComponentCard>
  );
}
